<template lang="">
  <v-container
    v-if="
      user.permissions.indexOf(permissionsAll.rol_create) >= 0 ||
        user.person.types_person.id === 3
    "
  >
    <BreadCrumbsVue :items="itemsBreadCrumbs" />
    <FormRol
      :actionForm="actionForm"
      @sendForm="sendForm"
      ref="formComponent"
    />
  </v-container>
</template>
<script>
import { beginRequest, endRequest, itemsBreadCrumbs } from "../../helpers";
import BreadCrumbsVue from "../../components/_commons/BreadCrumbs.vue";
import FormRol from "../../components/roles/FormRol.vue";
import { mapState } from "vuex";
import permissions from "../../helpers/permissions";

export default {
  name: "RolCreate",
  components: {
    BreadCrumbsVue,
    FormRol,
  },
  data() {
    return {
      itemsBreadCrumbs: itemsBreadCrumbs.rolesCreate,
      actionForm: "create",
      permissionsAll: permissions,
    };
  },
  computed: {
    ...mapState(["user"]),
  },
  methods: {
    async sendForm(formData) {
      await beginRequest();
      const { status, snack } = await this.$store.dispatch(
        "createRol",
        formData
      );
      if (status >= 200 && status < 300) {
        this.$refs.formComponent.resetFormMethod();
      }
      await endRequest(snack, status);
    },
  },
  async created() {
    await this.$store.dispatch("removeRoleSelectedDataAction");
  },
};
</script>
