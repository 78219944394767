<template lang="">
  <div>
    <LoaderLinear />
    <v-card elevation="0" class="pa-5 ma-2">
      <form @submit.prevent="sendForm">
        <v-row>
          <v-col cols="12" md="6">
            <v-text-field
              outlined
              v-model="$v.description.$model"
              label="Nombre del Rol (*)"
              :error-messages="descriptionErrors"
              @blur="$v.description.$touch()"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <span class="fvl-title-form">
              Permisos
            </span>
           
          </v-col>
        </v-row>
        <v-row class="ml-2">
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            class="pa-0"
            v-for="permission in persons.allPermissions"
            :key="permission.id"
          >
            <v-checkbox
              v-model="selectedPermission"
              :label="permission.name"
              :value="permission.name"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <!-- <v-divider></v-divider> -->
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <Button
              :disabled="$v.$invalid"
              classes="mr-4 float-right fvl-btn green-primary"
              type="submit"
              name="Guardar"
            />
            <router-link :to="goToBack">
              <Button
                :disabled="false"
                classes="mr-4 float-right fvl-btn green-military"
                type="button"
                name="Volver"
              />
            </router-link>
          </v-col>
        </v-row>
      </form>
      <Overlay />
    </v-card>
    <SnackBar />
  </div>
</template>
<script>
import { mapState } from "vuex";
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { beginRequest, endRequest, links } from "../../helpers";
import { LoaderLinear, SnackBar, Overlay, Button } from "../_commons";
import { getPermissions } from "../_selectors/permissions";

export default {
  name: "FormRol",
  props: ["actionForm"],
  components: {
    LoaderLinear,
    SnackBar,
    Overlay,
    Button,
  },
  mixins: [validationMixin],
  validations: {
    description: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(100),
    },
  },
  data() {
    return {
      description: "",
      goToBack: links.userRole.list,
      selectedPermission: [],
    };
  },
  methods: {
    sendForm() {
      const formData = {
        description: this.description,
        permissions: this.selectedPermission,
      };
      this.$emit("sendForm", formData);
    },
    resetFormMethod() {
      // EVENTO EMITIDO DESDE EL PADRE (SOLO CUANDO EL ACTION ES CREATE)
      this.description = "";
      this.selectedPermission = [];
      this.$v.$reset();
    },
  },
  computed: {
    ...mapState({
      persons: (state) => state.persons,
    }),
    descriptionErrors() {
      const errors = [];
      if (!this.$v.description.$dirty) return errors;
      !this.$v.description.minLength &&
        errors.push("La longitud minima es de 3 caracteres");
      !this.$v.description.maxLength &&
        errors.push("La longitud máxima es de 100 caracteres");
      !this.$v.description.required && errors.push("El nombre del rol es un campo requerido.");
      return errors;
    },
  },
  async created() {
    await beginRequest();
    let { snack, status } = await this.$store.dispatch(
      "setAllPermissionsAction"
    );
    if (this.actionForm === "Edit") {
      const { snack, status } = await this.$store.dispatch(
        "setRoleSelectedDataAction",
        this.$route.params.idrol
      );
      this.description = this.persons.roleSelectedData.types_person.description;
      this.selectedPermission = getPermissions(
        this.persons.roleSelectedData.types_person.permissions
      );
      await endRequest(snack, status);
      return;
    }
    await endRequest(snack, status);
  },
};
</script>
